var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"line-progress",class:{
      'canceled-line-progress': _vm.status[0] === 'canceled',
    },attrs:{"id":"progress"}}):_vm._e(),(!_vm.loading)?_c('span',{staticClass:"progress"},[_c('span',[_c('div',{staticClass:"box",class:{
          active: _vm.status[0] === 'active',
          canceled: _vm.color === '#FF0C37',
        }},[_c('svg',{attrs:{"width":"14","height":"24","viewBox":"0 0 14 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1","stroke":_vm.defineActive(_vm.status[0]),"stroke-width":"1.7","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('p',{staticClass:"status",class:{ canceledText: _vm.color === '#FF0C37' }},[_vm._v(" Pedido Pago ")])]),_c('span',[_c('div',{staticClass:"box",class:{
          active: _vm.status[1] === 'active',
          canceled: _vm.color === '#FF0C37',
        }},[_c('svg',{attrs:{"width":"20","height":"22","viewBox":"0 0 20 22","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8.39795L5.5 3.20795M1.27 5.95795L10 11.0079L18.73 5.95795M10 21.0779V10.9979M19 14.9979V6.99795C18.9996 6.64722 18.9071 6.30276 18.7315 5.99911C18.556 5.69546 18.3037 5.44331 18 5.26795L11 1.26795C10.696 1.09241 10.3511 1 10 1C9.64893 1 9.30404 1.09241 9 1.26795L2 5.26795C1.69626 5.44331 1.44398 5.69546 1.26846 5.99911C1.09294 6.30276 1.00036 6.64722 1 6.99795V14.9979C1.00036 15.3487 1.09294 15.6931 1.26846 15.9968C1.44398 16.3004 1.69626 16.5526 2 16.7279L9 20.7279C9.30404 20.9035 9.64893 20.9959 10 20.9959C10.3511 20.9959 10.696 20.9035 11 20.7279L18 16.7279C18.3037 16.5526 18.556 16.3004 18.7315 15.9968C18.9071 15.6931 18.9996 15.3487 19 14.9979Z","stroke":_vm.defineActive(_vm.status[1]),"stroke-width":"1.7","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('p',{staticClass:"status",class:{ canceledText: _vm.color === '#FF0C37' }},[_vm._v(" Expedição ")])]),_c('span',[_c('div',{staticClass:"box",class:{
          active: _vm.status[2] === 'active',
          canceled: _vm.color === '#FF0C37',
        }},[(_vm.status[2] !== 'canceled')?_c('svg',{attrs:{"width":"24","height":"20","viewBox":"0 0 24 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16 14V1H1V14H16ZM16 14H23V9L20 6H16V14ZM8 16.5C8 17.8807 6.88071 19 5.5 19C4.11929 19 3 17.8807 3 16.5C3 15.1193 4.11929 14 5.5 14C6.88071 14 8 15.1193 8 16.5ZM21 16.5C21 17.8807 19.8807 19 18.5 19C17.1193 19 16 17.8807 16 16.5C16 15.1193 17.1193 14 18.5 14C19.8807 14 21 15.1193 21 16.5Z","stroke":_vm.defineActive(_vm.status[2]),"stroke-width":"1.7","stroke-linecap":"round","stroke-linejoin":"round"}})]):_c('svg',{attrs:{"width":"15","height":"15","viewBox":"0 0 15 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14 1L1 14M1 1L14 14","stroke":_vm.defineActive(_vm.status[2]),"stroke-width":"1.7","stroke-linecap":"round","stroke-linejoin":"round"}})])]),(_vm.status[2] !== 'canceled')?_c('p',{staticClass:"status",class:{ canceledText: _vm.color === '#FF0C37' }},[_vm._v(" Enviado ")]):_c('p',{staticClass:"status",class:{ canceledText: _vm.color === '#FF0C37' }},[_vm._v(" Cancelado ")])]),_c('span',[_c('div',{staticClass:"box",class:{
          active: _vm.status[3] === 'active',
          canceled2: _vm.color === '#FF0C37',
        }},[_c('svg',{attrs:{"width":"20","height":"22","viewBox":"0 0 20 22","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11 1L1 13H10L9 21L19 9H10L11 1Z","stroke":_vm.defineActive(_vm.status[3]),"stroke-width":"1.7","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('p',{staticClass:"status",class:{ canceledText: _vm.color === '#FF0C37' }},[_vm._v(" Entregue ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }