<template>
  <div :class="{'card-menu':!isMobile}">
    <p class="title-status">Status atual</p>
    <h1>{{ filterStatus(dados.order_status) }}</h1>
    <div class="d-flex icon-text">
      <div class="box">
        <img src="@/assets/icons/cliente.svg" />
      </div>
      <p class="sub-title">Cliente</p>
    </div>
    <div class="base-dados">
      <div>
        <span>Nome</span>
        <b>{{ dados.client_name }}</b>
      </div>
      <div>
        <span>E-mail</span>
        <b>{{ dados.client_email }}</b>
      </div>
      <div>
        <span>Telefone</span>
        <b>{{ dados.client_cellphone }}</b>
      </div>
      <div>
        <span>CPF</span>
        <b>{{ dados.client_document }}</b>
      </div>
    </div>
    <div class="d-flex icon-text">
      <div class="box">
        <img src="@/assets/icons/entrega.svg" />
      </div>
      <p class="sub-title">Entrega</p>
    </div>
    <div class="base-dados">
      <div>
        <span>Destinatário</span>
        <b>{{ dados.client_name }}</b>
      </div>
      <div>
        <span>Endereço de entrega</span>
        <div class="endereco">
          <b>{{ dados.shipping_address_street }}, {{ dados.shipping_address_number }}</b>
          <b>{{ dados.shipping_address_city }} - {{ dados.shipping_address_state }}</b>
          <b>CEP: {{ dados.shipping_address_postal_code }}</b>
          <b>{{ dados.shipping_address_complement }}</b>
        </div>
      </div>
      <!-- <div>
        <span>Endereço de cobrança</span>
        <div class="endereco">
          <b>{{ dados.client_street }}, {{ dados.client_number }}</b>
          <b>{{ dados.client_city }} - {{ dados.client_state }}</b>
          <b>CEP: {{ dados.client_postal_code }}</b>
          <b>{{ dados.client_complement }}</b>
        </div>
      </div> -->
      <div class="d-flex to-gap">
        <div>
          <span>Transportadora</span>
          <b>{{ dados.service_name }}</b>
        </div>
        <div>
          <span>Rastreio</span>
          <b>{{ dados.tracking_code }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dados", "isMobile"],
  methods: {
    filterStatus(e) {
      if (e === "paid") {
        return "Pedido pago";
      } else if (e === "canceled") {
        return "Cancelado";
      } else if (e === "sent") {
        return "Enviado";
      } else if (e === "delivered") {
        return "Entregue";
      } else if (e === "dispatch") {
        return "Expedição";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-menu {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 50px 30px 30px 30px;
}

.base-dados {
  display: block;
  span {
    display: block;
    font-weight: normal;
    font-size: 13px;
    color: #81858e;
    margin-bottom: 7px;
  }
  b {
    display: block;
    font-weight: 600;
    font-size: 13px;
    color: #1b1e21;
    margin-bottom: 15px;
    white-space: nowrap;
  }
  .to-gap {
    gap: 30px;
  }
  .endereco {
    b {
      margin-bottom: 8px !important;
    }
    b:last-child {
      margin-bottom: 15px !important;
    }
  }
}

.icon-text {
  align-items: center;
  margin-bottom: 15px;
  margin-top: 40px;
  .box {
    width: 40px;
    height: 40px;
    background: linear-gradient(
      180deg,
      rgba(33, 51, 210, 0.1) 0%,
      rgba(33, 51, 210, 0) 100%
    );
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.title-status {
  font-weight: normal;
  font-size: 13px;
  color: #81858e;
}

.sub-title {
  font-weight: 600;
  font-size: 14px;
  color: #2133d2;
  margin-left: 15px;
  margin-bottom: 0;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  color: #2133d2;
}
</style>