import Rest from '@/services/Rest';

/**
 * @typedef {FiscalOrderService}
 */
export default class FiscalOrderService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'fiscal-center'
}