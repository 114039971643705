<template>
  <div :class="{ cardMenu: !isMobile }">
    <div class="header">
      <p class="id">#{{ dados.id }}</p>
      <span class="flex-status">
        <div>
          <p class="title-progress">Início</p>
          <p class="data-progress">{{ dados.created_at | datetime }}</p>
        </div>
        <span>
          <img src="@/assets/icons/arrow-progress.svg" alt="arrow" />
        </span>
        <div>
          <p v-if="dados.order_status === 'delivered'" class="title-progress">
            Entregue em
          </p>
          <p v-else class="title-progress">Estimado para</p>
          <p class="data-progress" v-if="dados.delivery_estimate === null">Sem Data</p>
          <p class="data-progress" v-else>{{ dados.delivery_estimate | datetime }}</p>
        </div>
      </span>
    </div>
    <div>
      <Progress
        v-if="!isMobile"
        ref="progress"
        :status="status"
        :loading="loading"
      />
      <div class="history" data-anima="top" @click="changeSection">
        <p>
          Histórico completo do pedido
          <svg
            width="13"
            height="7"
            viewBox="0 0 13 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="!history"
          >
            <path
              d="M1.5 1L6.5 6L11.5 1"
              stroke="#2133D2"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-else
            width="13"
            height="7"
            viewBox="0 0 13 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 6L6.5 1L1.5 6"
              stroke="#2133D2"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </p>
      </div>
      <section v-if="!history">
        <div :class="{ 'grid-inputs': !isMobile }">
          <b-form-group
            label="Status do pedido"
            label-for="status"
            v-if="!removeActions"
          >
            <b-dropdown
              id="dropdown-status"
              block
              class="status-drop"
              :text="filterStatus(dados.order_status)"
            >
              <b-dropdown-item @click="changeStatus('sent')">
                <div class="d-flex drop-status">
                  <div class="ball color1"></div>
                  <span>Enviado</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('delivered')">
                <div class="d-flex drop-status">
                  <div class="ball color2"></div>
                  <span>Entregue</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('paid')">
                <div class="d-flex drop-status">
                  <div class="ball color3"></div>
                  <span>Pedido pago</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('canceled')">
                <div class="d-flex drop-status">
                  <div class="ball color4"></div>
                  <span>Cancelado</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="changeStatus('dispatch')">
                <div class="d-flex drop-status">
                  <div class="ball color5"></div>
                  <span>Expedição</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
          <div v-else>
            <b-form-group label="Status do pedido" label-for="codigo">
              <b-form-input
                id="codigo"
                placeholder="Status do pedido"
                :value="filterStatus(dados.order_status)"
                type="text"
                disabled
                name="codigo"
              ></b-form-input>
            </b-form-group>
          </div>
          <div v-if="!isMobile">
            <div class="grid-rastreio" v-if="!removeActions">
              <b-form-group label="Código de rastreio" label-for="codigo">
                <b-form-input
                  id="codigo"
                  placeholder="Digite o código de rastreio"
                  v-model="dados.tracking_code"
                  type="text"
                  name="codigo"
                ></b-form-input>
              </b-form-group>
              <BaseButton
                class="aling-button"
                @click="changeCode"
                variant="primary"
                >Enviar</BaseButton
              >
            </div>
            <div v-else>
              <b-form-group label="Código de rastreio" label-for="codigo">
                <b-form-input
                  id="codigo"
                  placeholder="Digite o código de rastreio"
                  v-model="dados.tracking_code"
                  type="text"
                  disabled
                  name="codigo"
                ></b-form-input>
              </b-form-group>
            </div>
            <p class="detail-code" v-if="!removeActions">
              Ao enviar o código de rastreio o status do pedido será atualizado
              para ENVIADO automaticamente.
            </p>
          </div>

          <BaseButton
            v-if="dados.order_status === 'paid' && !removeActions"
            class="aling-button"
            :class="{ ticketBtn: !isMobile }"
            @click="gerarEtiqueta('not')"
            variant="black"
            >Gerar Etiqueta</BaseButton
          >

          <BaseButton
            v-if="dados.order_status !== 'paid' && !removeActions"
            class="aling-button"
            :class="{ ticketBtn: !isMobile }"
            @click="imprimirEtiqueta()"
            variant="white"
            >Imprimir Etiqueta</BaseButton
          >

          <BaseButton
            v-if="
              dados.order_status !== 'paid' &&
              dados.company_name === 'Jadlog' &&
              viewAgencyInfo === false
            "
            class="aling-button"
            :class="{ ticketBtn: !isMobile }"
            @click="viewAgency"
            variant="white"
            >Ver informações da Agência</BaseButton
          >

          <div
            v-if="viewAgencyInfo"
            @click="viewAgencyInfo = false"
            class="toSpan"
          >
            <span v-if="infoAgency.company_name">{{
              infoAgency.company_name
            }}</span>
            <br />
            <span v-if="infoAgency.address.address">{{
              infoAgency.address.address
            }}</span>
            <br>
            <span v-if="infoAgency.phone.phone">{{
              infoAgency.phone.phone
            }}</span>
          </div>
        </div>
        <div v-if="isMobile" class="mt-4">
          <div class="grid-rastreio" v-if="!removeActions">
            <b-form-group label="Código de rastreio" label-for="codigo">
              <b-form-input
                id="codigo"
                placeholder="Digite o código de rastreio"
                v-model="dados.tracking_code"
                type="text"
                name="codigo"
              ></b-form-input>
            </b-form-group>
            <BaseButton
              class="aling-button"
              @click="changeCode"
              variant="primary"
              >Enviar</BaseButton
            >
          </div>
          <div v-else>
            <b-form-group label="Código de rastreio" label-for="codigo">
              <b-form-input
                id="codigo"
                placeholder="Digite o código de rastreio"
                v-model="dados.tracking_code"
                type="text"
                disabled
                name="codigo"
              ></b-form-input>
            </b-form-group>
          </div>
          <p class="detail-code" v-if="!removeActions">
            Ao enviar o código de rastreio o status do pedido será atualizado
            para ENVIADO automaticamente.
          </p>
        </div>
        <div class="grid-pagamento" :class="{ 'pagamento-mobile': isMobile }">
          <div>
            <h1>Pagamento</h1>
            <div>
              <b>Método de Pagamento</b>
              <span class="mblast">{{ getMethod(dados.sale_method) }}</span>
            </div>
            <div>
              <b>Valor da transação</b>
              <span class="mblast"
                >R$ {{ formatMoney(+dados.sale_amount) }}</span
              >
            </div>
            <div>
              <b>Endereço de cobrança</b>
              <span>{{ dados.client_street }}, {{ dados.client_number }}</span>
              <span>{{ dados.client_city }} - {{ dados.client_state }}</span>
              <span>CEP: {{ dados.client_postal_code }}</span>
              <span>{{ dados.client_complement }}</span>
            </div>
          </div>
          <div>
            <h1>Produto</h1>
            <div class="product">
              <div class="d-flex separe-product">
                <div class="image-product">
                  <img
                    v-if="dados.product_image"
                    :src="dados.product_image"
                    alt="image"
                  />
                </div>
                <div>
                  <b>Nome do Produto</b>
                  <span class="mblast">{{ dados.product_name }}</span>
                </div>
                <span>R$ {{ formatMoney(+dados.sale_amount) }}</span>
              </div>
              <div class="d-flex separe-product" v-if="dados.is_gift">
                <div>
                  <b>Mensagem do Presenteável</b>
                  <span class="mblast">{{ dados.gift_message }}</span>
                </div>
              </div>
              <div class="d-flex separe-product" v-if="dados.coupon_discount">
                <div>
                  <b>Cupom de desconto</b>
                  <span class="mblast">{{ dados.coupon_name }}</span>
                </div>
                <span>R$ {{ formatMoney(+dados.coupon_discount) }}</span>
              </div>
              <div
                class="d-flex separe-product"
                v-if="dados.service_price_checkout"
              >
                <div>
                  <b>Frete Checkout</b>
                  <span class="mblast">{{ dados.service_name }}</span>
                </div>
                <span>R$ {{ formatMoney(+dados.service_price_checkout) }}</span>
              </div>
              <div class="d-flex separe-product" v-if="dados.service_price">
                <div>
                  <b>Frete</b>
                  <span class="mblast">{{ dados.service_name }}</span>
                </div>
                <span>R$ {{ formatMoney(+dados.service_price) }}</span>
              </div>
              <div class="d-flex separe-product">
                <b>Total</b>
                <b>R$ {{ formatMoney(+dados.sale_total) }}</b>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Historico :histories="dados.histories" :isMobile="isMobile" v-else />
    </div>
    <ModalTicketSteps :dados="dados" />
  </div>
</template>

<script>
import Progress from "@/components/Progress";
import Historico from "@/components/Historico.vue";
import ModalTicketSteps from "@/components/ModalTicketSteps.vue";

import Money from "@/mixins/money";

import OrderService from "@/services/resources/OrderService";
const service = OrderService.build();

export default {
  props: ["dados", "isMobile", "removeActions"],
  mixins: [Money],
  components: {
    Progress,
    Historico,
    ModalTicketSteps,
  },
  data() {
    return {
      infoAgency: "",
      viewAgencyInfo: false,
      status: ["active", "not", "not", "not"],
      history: false,
      loading: false,
    };
  },
  mounted() {
    this.setBarStatus(this.dados.order_status);
  },
  methods: {
    viewAgency() {
      this.getAgenciaData();
      this.viewAgencyInfo = true;
    },
    getAgenciaData() {
      service
        .postID({
          id: `melhor_envio/agencies?order_id=${this.dados.id}`,
        })
        .then((resp) => {
          console.log(resp);
          this.infoAgency = resp;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    gerarEtiqueta() {
      this.$root.$emit("bv::show::modal", "modal-ticket", "#btnShow");
    },
    imprimirEtiqueta() {
      if(!this.dados.ticket_url){
        this.$bvToast.toast('Não há etiqueta para ser imprimida.', {
          title: 'Etiqueta',
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      window.open(this.dados.ticket_url, "_blank");
    },
    getMethod(method) {
      let metodo = "";
      switch (method) {
        case "CREDIT_CARD": {
          metodo = "CARTÃO DE CRÉDITO";
          break;
        }
        case "TWO_CREDIT_CARDS": {
          metodo = "DOIS CARTÕES";
          break;
        }
        default: {
          metodo = method;
        }
      }
      return metodo;
    },
    setBarStatus(e) {
      if (e === "paid") {
        this.status = ["active", "not", "not", "not"];
      } else if (e === "canceled") {
        this.status = ["canceled", "canceled", "canceled", "not"];
      } else if (e === "sent") {
        this.status = ["active", "active", "active", "not"];
      } else if (e === "delivered") {
        this.status = ["active", "active", "active", "active"];
      } else if (e === "dispatch") {
        this.status = ["active", "active", "not", "not"];
      }
      setTimeout(() => {
        if (this.$refs.progress) {
          this.$refs.progress.getStatus();
        }
      }, 500);
    },
    changeStatus(e) {
      this.loading = true;
      service
        .update({
          id: this.dados.id,
          order_status: e,
        })
        .then((resp) => {
          console.log(resp);
          this.dados.order_status = e;
          this.setBarStatus(e);
          this.$bvToast.toast("O status foi atualizado com sucesso!", {
            title: "Status!",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterStatus(e) {
      if (e === "paid") {
        return "Pedido pago";
      } else if (e === "canceled") {
        return "Cancelado";
      } else if (e === "sent") {
        return "Enviado";
      } else if (e === "delivered") {
        return "Entregue";
      } else if (e === "dispatch") {
        return "Expedição";
      }
    },
    changeSection() {
      if (this.history === false) {
        this.history = true;
      } else {
        this.history = false;
      }
    },
    changeCode() {
      // this.loading = true;
      service
        .update({
          id: this.dados.id,
          tracking_code: this.dados.tracking_code,
        })
        .then((resp) => {
          console.log(resp);
          this.$bvToast.toast("O código foi atualizado com sucesso!", {
            title: "Status!",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // this.loading = false;
        });
    },
  },
};
</script>

<style>
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #1b1e21 !important;
}
</style>
<style lang="scss" scoped>
.ball {
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-right: 10px;
  background: black;
  border-radius: 100%;
}

.pagamento-mobile {
  grid-template-columns: 1fr !important;
  padding: 0 !important;
  margin-bottom: 40px;
}

.drop-status {
  font-weight: 600;
  font-size: 14px;
  height: 22px;
  color: #000000;
  span {
    margin-top: 4px;
  }
}

.color1 {
  background: #2133d2 !important;
}

.color2 {
  background: #00e4a0 !important;
}

.color3 {
  background: #ffcb37 !important;
}

.color4 {
  background: #ff0c37 !important;
}

.color5 {
  background: #37dbff !important;
}

.grid-rastreio {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 10px;
}

.aling-button {
  margin-top: auto;
  margin-bottom: 15px;
  height: 60px;
}

.cardMenu {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.grid-inputs {
  display: grid;
  grid-template-columns: 2fr 3fr;
  padding: 0 40px 30px 40px;
  margin-top: 30px;
  gap: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.grid-pagamento {
  display: grid;
  grid-template-columns: 3fr 4fr;
  padding: 0 40px 30px 40px;
  margin-top: 30px;
  gap: 20px;
  .product {
    .image-product {
      width: 60px;
      height: 60px;
      background: #e5e5e5;
      border-radius: 5px;
      margin-top: -20px;
      margin-bottom: 5px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .separe-product {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      margin-top: 15px;
    }
    .separe-product:last-child {
      border-bottom: 0px solid #e5e5e5;
    }
  }
  div {
    display: block;
  }
  h1 {
    font-weight: 600;
    font-size: 16px;
    color: #2133d2;
    margin-bottom: 30px;
  }
  b {
    font-weight: 600;
    display: block;
    font-size: 14px;
    color: #1b1e21;
    margin-bottom: 10px;
  }
  .mblast {
    margin-bottom: 25px;
  }
  span {
    font-weight: normal;
    font-size: 14px;
    color: #81858e;
    display: block;
    // margin-bottom: 25px;
  }
}

.history {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  cursor: pointer;
  p {
    font-weight: 600;
    font-size: 13px;
    color: #2133d2;
  }
}

.detail-code {
  font-weight: normal;
  font-size: 12px;
  color: #81858e;
  line-height: 16px !important;
}

.header {
  height: 72px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 43px 40px 26px 40px;
  position: relative;
}

.id {
  font-weight: 600;
  font-size: 18px;
  color: #1b1e21;
}

.title-progress {
  font-weight: 600;
  font-size: 13px;
}

.data-progress {
  font-weight: normal;
  font-size: 13px;
}

.flex-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  img {
    margin-top: -10px;
  }
}

.toSpan {
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
  display: block;
}
</style>