var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{attrs:{"name":"modal-ticket","size":"lg","title":"Gerar Ticket"},on:{"hidden":_vm.closeModal,"shown":_vm.openModal},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('BaseButton',{staticClass:"mr-3",attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" Fechar ")]),_c('BaseButton',{staticClass:"mr-3",attrs:{"variant":"primary"},on:{"click":_vm.gerarEtiqueta}},[_vm._v(" Gerar Etiqueta ")])]}}])},[_c('div',{staticClass:"set-fiscal",attrs:{"data-anima":"top"}},[_c('b-form-group',{attrs:{"label":"Seu produto é do tipo Comercial ","label-for":"codigo"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.comercial),callback:function ($$v) {_vm.comercial=$$v},expression:"comercial"}})],1),(!_vm.fiscal && !_vm.openGeration)?_c('BaseButton',{staticClass:"mr-3",attrs:{"variant":"outline-info"},on:{"click":_vm.addFiscal}},[_vm._v(" Adicionar Nota Fiscal ")]):_vm._e(),(_vm.openGeration)?_c('BaseButton',{staticClass:"mr-3 mb-3",attrs:{"variant":"black"},on:{"click":_vm.generateFiscal}},[_vm._v(" Gerar Nota Fiscal ")]):_vm._e(),(_vm.fiscal)?_c('BaseButton',{staticClass:"mr-3",attrs:{"variant":"link-info"},on:{"click":_vm.checkStatusFiscal}},[_vm._v(" Atualizar Status da NF ")]):_vm._e()],1),(_vm.comercial)?_c('div',{attrs:{"data-anima":"top"}},[(_vm.fiscal && _vm.selectIntegration)?_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Selecione a plataforma que deseja emitir a Chave da Nota Fiscal","label-for":"codigo"}},[_c('b-row',{staticClass:"mt-3 center-itens"},[_c('b-col',{staticClass:"ml-1",attrs:{"cols":"12","md":"3"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                title: 'NotaZZ',
                placement: 'top',
              }),expression:"{\n                title: 'NotaZZ',\n                placement: 'top',\n              }"}],staticClass:"mb-2 custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0"},on:{"click":function($event){return _vm.emitFiscal('NotaZZ')}}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"src":require("@/assets/icons/notaZZ.svg"),"alt":""}})])],1)],1)])],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Chave da Nota Fiscal ","label-for":"codigo"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### #### #### #### #### #### #### #### ####'),expression:"'#### #### #### #### #### #### #### #### #### #### ####'"}],attrs:{"placeholder":"Chave da Nota Fiscal","name":"codigo"},model:{value:(_vm.notaFiscal),callback:function ($$v) {_vm.notaFiscal=$$v},expression:"notaFiscal"}})],1)],1):_vm._e(),(_vm.options_shipping.length && !_vm.loading && !_vm.shipping_selected )?_c('div',{staticClass:"opcoes-frete",attrs:{"data-anima":"top"}},[(!_vm.shipping_selected && !_vm.loading)?_c('legend',[_vm._v("Selecione o Frete")]):_vm._e(),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var ariaDescribedby = ref.ariaDescribedby;
return _vm._l((_vm.options_shipping),function(frete){return _c('div',{key:frete.id,staticClass:"frete-item"},[_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"frete","value":!frete.error ? frete.id : null,"disabled":frete.error ? true : false},on:{"change":function($event){return _vm.changeFrete(frete)}},model:{value:(_vm.shipping.service_id),callback:function ($$v) {_vm.$set(_vm.shipping, "service_id", $$v)},expression:"shipping.service_id"}},[(!frete.error)?_c('div',{staticClass:"infos-frete"},[(frete.company && frete.company.picture)?_c('img',{attrs:{"src":frete.company.picture}}):_vm._e(),_c('div',{staticClass:"info-envio"},[_c('span',[_vm._v(" "+_vm._s(frete.name)+" ")]),(frete.delivery_range)?_c('span',[_vm._v(" "+_vm._s(frete.delivery_range.min)+" à "+_vm._s(frete.delivery_range.max)+" dias úteis ")]):_vm._e(),_c('span',{staticClass:"frete-valor"},[_vm._v(" R$ "+_vm._s(_vm.formatMoney(+frete.price))+" ")])])]):_c('div',{staticClass:"infos-frete",staticStyle:{"width":"100%"}},[(frete.company && frete.company.picture)?_c('img',{attrs:{"src":frete.company.picture}}):_vm._e(),_c('div',{staticClass:"info-envio-error"},[_c('span',[_vm._v(" "+_vm._s(frete.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(frete.error)+" ")])])])])],1)})}}],null,false,3009231250)})],1):_vm._e(),(!_vm.options_shipping.length && !_vm.shipping_selected && !_vm.loading)?_c('div',{staticClass:"opcoes-frete",attrs:{"data-anima":"top"}},[_c('b-row',{staticClass:"Table-body justify-content-center"},[_c('p',{staticClass:"nao-encontrado"},[_vm._v("Nenhuma transportadora foi encontrada")])])],1):_vm._e(),(_vm.shipping_selected && !_vm.loading)?_c('div',{staticClass:"opcoes-frete",attrs:{"data-anima":"top"}},[_c('b-row',{staticClass:"Table-body justify-content-center"},[_c('legend',[_vm._v("Frete Selecionado")]),_c('div',{staticClass:"frete-item",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"infos-frete",staticStyle:{"width":"100%"}},[(
              _vm.shipping_selected.frete.company &&
              _vm.shipping_selected.frete.company.picture
            )?_c('img',{attrs:{"src":_vm.shipping_selected.frete.company.picture}}):_vm._e(),_c('div',{staticClass:"info-envio-error"},[_c('span',[_vm._v(" "+_vm._s(_vm.shipping_selected.frete.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.shipping_selected.frete.delivery_range.min)+" à "+_vm._s(_vm.shipping_selected.frete.delivery_range.max)+" dias úteis ")]),_c('span',[_vm._v(" "+_vm._s(_vm.shipping_selected.frete.error)+" ")])])])])])],1):_vm._e(),(
      _vm.shipping &&
      _vm.shipping.frete &&
      _vm.shipping.frete.company.name === 'Latam Cargo' &&
      !_vm.loading
    )?_c('div',{attrs:{"data-anima":"top"}},[_c('b-form-group',{attrs:{"label":"Código CNAE","label-for":"codigo"}},[_c('b-form-input',{attrs:{"placeholder":"Código CNAE"},model:{value:(_vm.codigoCNAE),callback:function ($$v) {_vm.codigoCNAE=$$v},expression:"codigoCNAE"}})],1)],1):_vm._e(),(_vm.jadlog && !_vm.loading)?_c('div',{attrs:{"data-anima":"top"}},[_c('b-form-group',{attrs:{"label":"Selecione uma Agência","label-for":"agencia"}},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value-field":"id","text-field":"name","options":_vm.optionsAgencia,"name":"agencia"},model:{value:(_vm.selectedAgencia),callback:function ($$v) {_vm.selectedAgencia=$$v},expression:"selectedAgencia"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errors.has('agencia')}},[_vm._v(" Por favor, selecione a agência. ")])],1)],1):_vm._e(),(_vm.nota_dados.status != 'NOT_ISSUED' || _vm.nota_dados.status != 'ERROR')?_c('b-row',{staticClass:"Table-body justify-content-center"},[(_vm.nota_dados.status == 'PROCESSING')?_c('p',{staticClass:"nao-encontrado"},[_vm._v(" Nota Fiscal em processamento. "),_c('br'),_c('br'),_vm._v("Por favor aguarde ")]):_vm._e(),(_vm.nota_dados.status == 'ISSUED_EXTERNALLY')?_c('p',{staticClass:"nao-encontrado"},[_vm._v(" Nota Fiscal emitida externamente. ")]):_vm._e(),(_vm.nota_dados.status == 'ISSUED')?_c('p',{staticClass:"nao-encontrado"},[_vm._v(" ✨ Nota Fiscal emitida com sucesso "),_c('br'),_c('br'),_vm._v(" Emissão: "+_vm._s(_vm.formatDate(_vm.nota_dados.emitted_at))+" "),_c('br'),_c('br'),_vm._v(" Integração: "+_vm._s(_vm.nota_dados.issued_by_integration)+" "),_c('br'),_c('br'),_vm._v(" Código da NF: "+_vm._s(_vm.nota_dados.key_note)+" ")]):_vm._e()]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }