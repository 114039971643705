<template>
  <span>
    <section
      class="history-section"
      :class="{'history-mobile':isMobile == true}"
      data-anima="top"
      v-for="historie in histories"
      :key="historie.id"
    >
      <div class="history-status" data-anima="top">
        <div class="icon-city">
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2727 11.6364V1H1V11.6364H13.2727ZM13.2727 11.6364H19V7.54545L16.5455 5.09091H13.2727V11.6364ZM6.72727 13.6818C6.72727 14.8115 5.81149 15.7273 4.68182 15.7273C3.55214 15.7273 2.63636 14.8115 2.63636 13.6818C2.63636 12.5521 3.55214 11.6364 4.68182 11.6364C5.81149 11.6364 6.72727 12.5521 6.72727 13.6818ZM17.3636 13.6818C17.3636 14.8115 16.4479 15.7273 15.3182 15.7273C14.1885 15.7273 13.2727 14.8115 13.2727 13.6818C13.2727 12.5521 14.1885 11.6364 15.3182 11.6364C16.4479 11.6364 17.3636 12.5521 17.3636 13.6818Z"
              stroke="#2133D2"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div>
            <b>{{ historie.after.event_message }}</b>
            <span>{{ historie.after.event_location }}</span>
          </div>
        </div>
        <div>
          <b>{{ historie.updated_at | date }}</b>
          <span>{{ historie.updated_at | hours }}</span>
        </div>
      </div>
    </section>
  </span>
</template>

<script>
import moment from "moment";

export default {
  props: ["histories", "isMobile"],
  filters: {
    date(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },
    hours(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
    },
  },
  mounted() {
    console.log(this.histories);
  },
};
</script>

<style lang="scss" scoped>
.history-mobile{
  padding: 0 !important;
}

.history-section {
  width: 100%;
  padding: 0 40px 5px 40px;
  // margin-top: 20px;
  .history-status {
    height: 102px;
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 7px;
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
    align-items: center;
  }
  .icon-city {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  b,
  span {
    margin-bottom: 10px;
    text-transform: capitalize;
    display: block;
  }
}
</style>